import React from "react"
import { Modal } from "react-bootstrap"
import HubspotContactForm from "./hubspot-contact-form"
import { useTranslation } from "react-i18next";

interface IProps {
    show: boolean
    close: Function
    withoutSidebar?: boolean
}
function HubspotContactModal(props: IProps) {
    const { show, close, withoutSidebar } = props
    const { t } = useTranslation()
    return (
        <Modal show={show} onHide={close} className={`hubspot-contact-modal ${withoutSidebar ? "centered" : ""}`}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Family Newsletter")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <HubspotContactForm />
            </Modal.Body>
        </Modal>
    )
}

export default HubspotContactModal
